@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-indicator:before {
  content: "";
  background: #00000060;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: "Loading...";
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 10010;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
}
